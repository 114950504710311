<template>
  <div>
    <div v-if="exercice == null">
      <div class="spinner-grow spinner-grow-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-if="exercice != null" class="container d-flex align-items-center p-0">
      <div class="row">
        <div class="col-auto" style="padding-right: 0 !important">
          <img
            :src="exercice.photo.thumbnail"
            class="rounded"
            alt="exercice"
            width="30px"
          />
        </div>
        <div class="col">
          <small>{{ exercice.description.it.nom }}</small>
          <div
            class="alert alert-danger"
            v-if="
              exercicetype == 'Video' &&
              exerciceref.path.split('/')[0] != 'videos'
            "
          >
            ERROR TYPE
          </div>
          <div
            class="alert alert-danger"
            v-if="
              exercicetype != 'Video' &&
              exerciceref.path.split('/')[0] == 'videos'
            "
          >
            ERROR TYPE
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Exercice",
  props: ["exerciceref", "exercicetype"],
  components: {},
  data() {
    return {
      exercice: null,
      idexercice: null,
    };
  },
  mounted() {
    this.showExerciceFromRef();
  },
  methods: {
    async showExerciceFromRef() {
      var getRef = await this.exerciceref.get();
      this.exercice = getRef.data();
      this.idexercice = getRef.id;
    },
  },
};
</script>